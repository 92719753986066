import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginAdminPage from './components/pages/LoginAdminPage';
import DashboardAdminPage from './components/pages/DashboardAdminPage';
import ManageAccountsPage from './components/pages/ManageAccountsPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/admin" />} /> {/* Redirect către pagina de Admin Login */}
        <Route path="/admin" element={<LoginAdminPage />} />
        <Route path="/dashboard" element={<DashboardAdminPage />} />
        <Route path="/manage" element={<ManageAccountsPage />} />
      </Routes>
    </Router>
  );
};

export default App;
