import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  ArcElement, // pentru graficele de tip doughnut
  CategoryScale, // pentru axele grafice
  LinearScale, // pentru scalele liniare pe grafic
  BarElement, // pentru graficele de tip bar
  PointElement, // pentru graficele de tip line
  LineElement, // pentru liniile grafice
  Title, // pentru titluri
  Tooltip, // pentru tooltip-uri
  Legend // pentru legende
} from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import axios from 'axios';
import  '../../styles/DashboardAdminPage.css';

// Înregistrare module necesare în Chart.js
ChartJS.register(
  ArcElement, // pentru graficele de tip doughnut
  CategoryScale, // pentru axele de categorie (ex: axa X)
  LinearScale, // pentru axa Y
  BarElement, // pentru graficele de tip bar
  PointElement, // pentru punctele de pe graficele line
  LineElement, // pentru graficele de tip line
  Title, // pentru titluri
  Tooltip, // pentru tooltip-uri
  Legend // pentru legendă
);

const DashboardAdminPage = () => {
  // Restul codului paginii DashboardAdminPage
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [packageCounts, setPackageCounts] = useState({});
  const [paymentCounts, setPaymentCounts] = useState({});
  const [upgradeCounts, setUpgradeCounts] = useState(0);
  const [downgradeCounts, setDowngradeCounts] = useState(0);
  const [deactivatedCounts, setDeactivatedCounts] = useState(0);
  const [deletedCounts, setDeletedCounts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL.endsWith('/')
          ? process.env.REACT_APP_API_URL.slice(0, -1)
          : process.env.REACT_APP_API_URL;

        const token = sessionStorage.getItem('authToken');
        
        // Request pentru a obține datele clienților
        const response = await axios.get(`${apiUrl}/dashboard`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const clientsData = response.data;

        setClients(clientsData);

        // Calculare pachete
        const counts = clientsData.reduce((acc, client) => {
          acc[client.package] = (acc[client.package] || 0) + 1;
          return acc;
        }, {});
        setPackageCounts(counts);

        // Calculare tipuri de plată
        const paymentTypes = clientsData.reduce((acc, client) => {
          acc[client.subscriptionType] = (acc[client.subscriptionType] || 0) + 1;
          return acc;
        }, {});
        setPaymentCounts(paymentTypes);

        // Calculare acțiuni (upgrades, downgrades, etc.)
        const upgrade = clientsData.filter(client => client.upgraded).length;
        const downgrade = clientsData.filter(client => client.downgraded).length;
        const deactivated = clientsData.filter(client => !client.active).length;
        const deleted = clientsData.filter(client => client.deleted).length;

        setUpgradeCounts(upgrade);
        setDowngradeCounts(downgrade);
        setDeactivatedCounts(deactivated);
        setDeletedCounts(deleted);

        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
        console.error(err);
      }
    };

    fetchClients();
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('authToken');
    navigate('/admin');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const packageData = {
    labels: Object.keys(packageCounts),
    datasets: [
      {
        label: 'Packages',
        data: Object.values(packageCounts),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  const paymentData = {
    labels: Object.keys(paymentCounts),
    datasets: [
      {
        label: 'Payment Methods',
        data: Object.values(paymentCounts),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const upgradeData = {
    labels: ['Upgrades', 'Downgrades', 'Deactivated', 'Deleted'],
    datasets: [
      {
        label: 'Account Actions',
        data: [upgradeCounts, downgradeCounts, deactivatedCounts, deletedCounts],
        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  const clientGrowthData = {
    labels: clients.map(client => new Date(client.createdAt).toLocaleDateString()),
    datasets: [
      {
        label: 'Client Growth Over Time',
        data: clients.map((_, index) => index + 1),
        fill: false,
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
      },
    ],
  };

  return (
    <div className="dashboard-page">
      <h1>Admin Dashboard</h1>
      <div className="chart-container">
        <h2>Package Distribution</h2>
        <Doughnut data={packageData} />
      </div>
      <div className="chart-container">
        <h2>Payment Methods Distribution</h2>
        <Bar data={paymentData} />
      </div>
      <div className="chart-container">
        <h2>Account Actions</h2>
        <Bar data={upgradeData} />
      </div>
      <div className="chart-container">
        <h2>Client Growth Over Time</h2>
        <Line data={clientGrowthData} />
      </div>
      <div className="button-group">
        <button onClick={() => navigate('/manage')}>Manage Accounts</button>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default DashboardAdminPage;
