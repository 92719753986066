import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/ManageAccountsPage.module.css';

const ManageAccountsPage = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [notes, setNotes] = useState([]);
  const [viewNewClients, setViewNewClients] = useState(true);
  const [showNotesForClient, setShowNotesForClient] = useState(null);
  const navigate = useNavigate();

  // Fetch clients when component mounts
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL.endsWith('/')
          ? process.env.REACT_APP_API_URL.slice(0, -1)
          : process.env.REACT_APP_API_URL;

        const response = await axios.get(`${apiUrl}/admin/manage`, 
            { withCredentials: true });

        // Asigură-te că răspunsul este un array
        if (Array.isArray(response.data)) {
          setClients(response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        } else {
          console.error('Expected an array of clients, but got:', response.data);
        }
      } catch (err) {
        console.error('Error fetching clients:', err);
        if (err.response?.status === 401) {
          navigate('/login');
        }
      }
    };
    fetchClients();
  }, [navigate]);

  // Activate client account
  const handleActivate = async (email) => {
    const clientToActivate = clients.find(client => client.email === email);
    const { subscriptionType, contractStart, numberOfTrucks, numberOfDrivers, numberOfAccountants } = clientToActivate;

    if (!numberOfTrucks || !numberOfDrivers || !numberOfAccountants) {
      alert('Completează toate câmpurile obligatorii pentru activare!');
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL.endsWith('/')
        ? process.env.REACT_APP_API_URL.slice(0, -1)
        : process.env.REACT_APP_API_URL;

      const response = await axios.post(`${apiUrl}/admin/activate`, {
        email,
        subscriptionType,
        contractStart,
        numberOfTrucks,
        numberOfDrivers,
        numberOfAccountants
      }, { withCredentials: true });

      alert(response.data.message);
      setClients(clients.map(client => client.email === email ? { ...client, active: true } : client));
    } catch (err) {
      console.error('Activation error:', err);
      alert('A apărut o eroare. Te rog încearcă din nou.');
    }
  };

  // Deactivate client account
  const handleDeactivate = async (email) => {
    const clientToDeactivate = clients.find(client => client.email === email);
    const { numberOfTrucks, numberOfDrivers, numberOfAccountants } = clientToDeactivate;

    if (!numberOfTrucks || !numberOfDrivers || !numberOfAccountants) {
      alert('Completează toate câmpurile obligatorii pentru dezactivare!');
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL.endsWith('/')
        ? process.env.REACT_APP_API_URL.slice(0, -1)
        : process.env.REACT_APP_API_URL;

      const response = await axios.post(`${apiUrl}/admin/deactivate`, {
        email,
        numberOfTrucks,
        numberOfDrivers,
        numberOfAccountants
      }, { withCredentials: true });

      alert(response.data.message);
      setClients(clients.map(client => client.email === email ? { ...client, active: false } : client));
    } catch (err) {
      console.error('Deactivation error:', err);
      alert('A apărut o eroare. Te rog încearcă din nou.');
    }
  };

  // Edit client information
  const handleEdit = (client) => {
    setSelectedClient(client);
    setNotes(client.notes || []);
    setShowNotesForClient(null);
  };

  // Save edited client
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL.endsWith('/')
        ? process.env.REACT_APP_API_URL.slice(0, -1)
        : process.env.REACT_APP_API_URL;

      const updatedClient = {
        ...selectedClient,
        notes,
        numberOfTrucks: selectedClient.numberOfTrucks,
        numberOfDrivers: selectedClient.numberOfDrivers,
        numberOfAccountants: selectedClient.numberOfAccountants
      };

      const response = await axios.put(`${apiUrl}/clients/${selectedClient._id}`, updatedClient, {
        withCredentials: true,
      });

      setClients(clients.map(client => client._id === selectedClient._id ? updatedClient : client));
      setSelectedClient(null);
    } catch (err) {
      console.error('Save error:', err);
      if (err.response && err.response.status === 401) {
        alert('Sesiunea a expirat. Te rog autentifică-te din nou.');
        navigate('/login');
      } else {
        alert('A apărut o eroare. Te rog încearcă din nou.');
      }
    }
  };

  // Delete client
  const handleDelete = async (id) => {
    if (window.confirm('Ești sigur că vrei să ștergi acest cont?')) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL.endsWith('/')
          ? process.env.REACT_APP_API_URL.slice(0, -1)
          : process.env.REACT_APP_API_URL;

        await axios.delete(`${apiUrl}/clients/${id}`, { withCredentials: true });

        setClients(clients.filter(client => client._id !== id));
      } catch (err) {
        console.error('Delete error:', err);
        alert('A apărut o eroare. Te rog încearcă din nou.');
      }
    }
  };

  // Handle subscription type change
  const handleSubscriptionChange = (type) => {
    const contractEnd = calculateContractEnd(selectedClient.contractStart, type);
    setSelectedClient({ ...selectedClient, subscriptionType: type, contractEnd });
  };

  // Calculate contract end date based on subscription type
  const calculateContractEnd = (startDate, subscriptionType) => {
    const date = new Date(startDate);
    if (isNaN(date)) return '';

    if (subscriptionType === 'Monthly') {
      date.setMonth(date.getMonth() + 1);
    } else if (subscriptionType === 'Yearly') {
      date.setFullYear(date.getFullYear() + 1);
    } else if (subscriptionType === '3 Day Test') {
      date.setDate(date.getDate() + 3);
    }

    return date.toISOString().split('T')[0];
  };

  // Handle contract start date change
  const handleContractStartChange = (e) => {
    const contractStart = e.target.value;
    const contractEnd = calculateContractEnd(contractStart, selectedClient.subscriptionType);
    setSelectedClient({ ...selectedClient, contractStart, contractEnd });
  };

  // Add a new note to client
  const handleAddNote = () => {
    setNotes([...notes, '']);
  };

  // Handle note change
  const handleNoteChange = (index, value) => {
    const newNotes = [...notes];
    newNotes[index] = value;
    setNotes(newNotes);
  };

  // Toggle showing notes for a specific client
  const toggleShowNotes = (clientEmail) => {
    setShowNotesForClient(clientEmail === showNotesForClient ? null : clientEmail);
  };

  // Filter clients based on active or new status
  const filterClients = (newClients) => {
    return clients.filter(client => newClients ? !client.active : client.active);
  };

  return (
    <div className={styles.manageAccountsPage}>
      <h1 className={styles.title}>Gestionare Conturi</h1>
      <div className={styles.buttonGroup}>
        <button onClick={() => setViewNewClients(false)}>Conturi Active</button>
        <button onClick={() => setViewNewClients(true)}>Conturi Noi</button>
        <button onClick={() => navigate('/dashboard')}>Dashboard</button>
        <button onClick={async () => {
          await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true });
          navigate('/admin');
        }}>Logout</button>
      </div>
      <div className={styles.content}>
        <ul className={styles.clientsList}>
          {filterClients(viewNewClients).map(client => (
            <li key={client.email} className={`${styles.clientItem} ${client.active ? styles.active : styles.inactive}`}>
              <div className={styles.clientDetails}>
                <span>{client.companyName}</span>
                <span>{client.companyAddress}</span>
                <span>{client.firstName}</span>
                <span>{client.lastName}</span>
                <span>{client.email}</span>
                <span>{client.phone}</span>
                <span>{client.mcNumber}</span>
                <span>{client.package}</span>
              </div>
              <div className={styles.clientActions}>
                {!client.active && <button onClick={() => handleActivate(client.email)}>Activează</button>}
                {client.active && <button onClick={() => handleDeactivate(client.email)}>Dezactivează</button>}
                <button onClick={() => handleEdit(client)}>Editează</button>
                <button onClick={() => handleDelete(client._id)}>Șterge</button>
                <button onClick={() => toggleShowNotes(client.email)}>Vezi Notițele</button>
              </div>
              {showNotesForClient === client.email && (
                <div className={styles.notesSection}>
                  {client.notes && client.notes.map((note, index) => (
                    <p key={index}>{note}</p>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>

        {selectedClient && (
          <div className={styles.editClientModal}>
            <h2>Editează informațiile clientului</h2>
            <form onSubmit={handleSave}>
              <input type="text" name="companyName" value={selectedClient.companyName || ''} onChange={(e) => setSelectedClient({ ...selectedClient, companyName: e.target.value })} required />
              <input type="text" name="companyAddress" value={selectedClient.companyAddress || ''} onChange={(e) => setSelectedClient({ ...selectedClient, companyAddress: e.target.value })} required />
              <input type="text" name="firstName" value={selectedClient.firstName || ''} onChange={(e) => setSelectedClient({ ...selectedClient, firstName: e.target.value })} required />
              <input type="text" name="lastName" value={selectedClient.lastName || ''} onChange={(e) => setSelectedClient({ ...selectedClient, lastName: e.target.value })} required />
              <input type="email" name="email" value={selectedClient.email || ''} onChange={(e) => setSelectedClient({ ...selectedClient, email: e.target.value })} required />
              <input type="phone" name="phone" value={selectedClient.phone || ''} onChange={(e) => setSelectedClient({ ...selectedClient, phone: e.target.value })} required />
              <input type="text" name="mcNumber" value={selectedClient.mcNumber || ''} onChange={(e) => setSelectedClient({ ...selectedClient, mcNumber: e.target.value })} required />

              <label>Număr de Camioane</label>
              <input type="number" name="numberOfTrucks" value={selectedClient.numberOfTrucks || ''} onChange={(e) => setSelectedClient({ ...selectedClient, numberOfTrucks: e.target.value })} required />

              <label>Număr de Șoferi</label>
              <input type="number" name="numberOfDrivers" value={selectedClient.numberOfDrivers || ''} onChange={(e) => setSelectedClient({ ...selectedClient, numberOfDrivers: e.target.value })} required />

              <label>Număr de Contabili</label>
              <input type="number" name="numberOfAccountants" value={selectedClient.numberOfAccountants || ''} onChange={(e) => setSelectedClient({ ...selectedClient, numberOfAccountants: e.target.value })} required />

              <select name="subscriptionType" value={selectedClient.subscriptionType || ''} onChange={(e) => handleSubscriptionChange(e.target.value)}>
                <option value="Monthly">Lunar</option>
                <option value="Yearly">Anual</option>
                <option value="3 Day Test">Test de 3 zile</option>
              </select>

              <input type="date" name="contractStart" value={selectedClient.contractStart ? selectedClient.contractStart.split('T')[0] : ''} onChange={handleContractStartChange} required />
              <input type="date" name="contractEnd" value={selectedClient.contractEnd ? selectedClient.contractEnd.split('T')[0] : ''} readOnly required />

              <div className={styles.notesSection}>
                <h3>Notițe</h3>
                {notes.map((note, index) => (
                  <div key={index}>
                    <input type="text" value={note} onChange={(e) => handleNoteChange(index, e.target.value)} />
                  </div>
                ))}
                <button type="button" onClick={handleAddNote}>+</button>
              </div>

              <button type="submit">Salvează modificările</button>
              <button type="button" onClick={() => setSelectedClient(null)}>Anulează</button>
            </form>
          </div>
        )}

      </div>
    </div>
  );
};

export default ManageAccountsPage;
