import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../styles/LoginAdminPage.css';

const LoginAdminPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setError('');
    setLoading(true);

    if (!username || !password) {
      setError('Please enter both username and password.');
      setLoading(false);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '');
      
      console.log(`API URL: ${apiUrl}`);  // Debugging: verifică URL-ul
      console.log(`Login data: ${username}, ${password}`);  // Debugging: verifică datele trimise

      const response = await axios.post(
        `${apiUrl}/admin/auth/login`,  // Schimbă ruta la cea corectă
        { username, password },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      console.log('Response:', response);  // Debugging: verifică răspunsul

      if (response.status === 200 && response.data.accessToken) {
        setUsername('');
        setPassword('');
        setLoading(false);
        navigate('/manage');
      } else {
        setError('Login failed: Token missing or invalid.');
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err.response);  // Debugging: vezi eroarea exactă din răspunsul serverului
      if (err.response) {
        setError(`Server error: ${err.response.data.error || 'Unknown error'}`);
      } else {
        setError('No response from server. Check your network or CORS configuration.');
      }
    }
  };

  return (
    <div className="login-page">
      <h1>Admin Login</h1>
      <div className="login-form">
        <input
          type="text"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin} disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default LoginAdminPage;
